import * as React from 'react';
import Helmet from 'react-helmet';

import Twitter from 'assets/svg/twitter.svg';
import Github from 'assets/svg/github.svg';
import Linkedin from 'assets/svg/linkedin.svg';
import BuyMeACoffee from 'assets/svg/buy-me-a-coffee.svg';

import { helmet } from 'utils/helmet';
import { Header } from 'components/header/Header';
import { Link as HeaderLink } from 'components/header/Link';
import { Footer } from 'components/footer/Footer';
import { Devtools } from 'components/devtools/Devtools';

import s from './AppLayout.scss';

interface IAppLayoutProps {
  children: React.ReactNode;
}

const isDev = process.env.NODE_ENV === 'development';

// tslint:disable no-default-export
export default ({ children }: IAppLayoutProps) => (
  <div className={s.layout}>
    <Helmet {...helmet} />

    <Header>
      <HeaderLink
        name="consulting"
        to="/consulting"
      />

      <HeaderLink
        name="blog"
        to="https://emilol.com"
      />
    </Header>

    {children}

    <Footer
      social={[
        { icon: <Github />, to: 'https://github.com/emilol' },
        { icon: <Twitter />, to: 'https://twitter.com/emilol' },
        { icon: <Linkedin />, to: 'https://www.linkedin.com/in/emilol' },
        { icon: <BuyMeACoffee />, to: 'https://buymeacoffee.com/emilol' },
      ]}
    />

    {isDev && <Devtools />}
  </div>
);
