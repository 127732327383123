// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("C:\\Code\\Samples\\ueno-gatsby-starter\\src\\pages\\404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-consulting-tsx": () => import("C:\\Code\\Samples\\ueno-gatsby-starter\\src\\pages\\consulting.tsx" /* webpackChunkName: "component---src-pages-consulting-tsx" */),
  "component---src-pages-index-tsx": () => import("C:\\Code\\Samples\\ueno-gatsby-starter\\src\\pages\\index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\Code\\Samples\\ueno-gatsby-starter\\.cache\\data.json")

